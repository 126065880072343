import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./styles.css";
import { DialerSip } from "@mui/icons-material";

export function FaleConosco() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function sendEmail(e) {
    e.preventDefault();
    const templateParams = {
      from_name: name,
      message: message,
      email: email,
    };

    try {
      const response = await emailjs.send(
        "service_v80ocnu",
        "template_a60vcpu",
        templateParams,
        "qNNDcIyAIuGREhrfy"
      );
      console.log("EMAIL ENVIADO", response.status, response.text);
      setName("");
      setMessage("");
      setEmail("");
      setSuccessMessage("E-mail enviado com sucesso!");
    } catch (err) {
      console.log("Erro:", err);
      setErrorMessage("Ocorreu um erro. Por favor, tente novamente.");
    }
  }

  function clearMessages() {
    setSuccessMessage("");
    setErrorMessage("");
  }

  return (
    <div className="container-faleconosco">
      <h1 className="faleconosco">FALE CONOSCO</h1>
      <div className="overlay">
        <div className="text-box">
          Este formulário direciona sua mensagem diretamente ao Engenheiro
          responsável da empresa, <strong> Gustavo P. Barreto.</strong> Formado
          em Engenharia Mecânica e Engenharia Civil, possui uma sólida
          trajetória profissional, destacando-se por sua vasta experiência em
          processos periciais. Com mais de 5 anos dedicados exclusivamente à
          perícia e uma carreira de mais de 15 anos na engenharia, sua formação
          e experiência garantem um atendimento técnico e especializado.
        </div>
      </div>
      <p className="faleconosco">
        Tem alguma pergunta? Entre em contato conosco:
      </p>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <form className="contact-form" onSubmit={sendEmail}>
        <input
          type="text"
          placeholder="Seu nome"
          onChange={(e) => {
            setName(e.target.value);
            clearMessages();
          }}
          value={name}
          required
        />

        <input
          type="email"
          placeholder="Seu e-mail"
          onChange={(e) => {
            setEmail(e.target.value);
            clearMessages();
          }}
          value={email}
          required
        />

        <textarea
          placeholder="Sua mensagem"
          onChange={(e) => {
            setMessage(e.target.value);
            clearMessages();
          }}
          value={message}
          required
        ></textarea>

        <button type="submit">Enviar</button>
      </form>
    </div>
  );
}
