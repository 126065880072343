import React from "react";
import "./styles.css";
import Fundo from "../../assets/fundo-principal.mp4";
import Engrenagem from "../../assets/engrenagem2.png";
import { Link } from "react-router-dom";
//import { Counter } from "../../components/Counter";

export function Inicio() {
  return (
    
    <div className="container-principal">
      <video autoPlay muted className="background-video">
        <source src={Fundo} type="video/mp4" />
      </video>
      <div className="gear-container">
        <div className="gear-wrapper gear-wrapper1">
          <img
            src={Engrenagem}
            alt="Engrenagem"
            className="gear-image gear-image1"
          />
        </div>

        <div className="gear-text gear-text1">
          <div>Perícia</div>
          <div>na Rede</div>
          <div className="gear-text gear-text3">
            <div>Engenheiros</div>
            <div>Especializados</div>
          </div>
        </div>

        <div className="gear-wrapper gear-wrapper2">
          <img
            src={Engrenagem}
            alt="Engrenagem"
            className="gear-image gear-image2"
          />
        </div>

        <div className="gear-text gear-text2">
          <div>Perícia Judicial </div>
          <div>Engenharia Legal</div>
          <div>Assistências Técnicas</div>
          <div>
          <Link to="/utilitarios">
            <button>Utilitários</button>
          </Link>
          </div>
        </div>
        </div>
      </div>
  );
}
