import "./App.css";
import { Footer } from "./components/Footer";
import { Head } from "./components/Head";
import { WhatsAppButton } from "./components/Zap";
import { FaleConosco } from "./pages/FaleConosco";
import { Inicio } from "./pages/Inicio";
import { Registros } from "./pages/Registros";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EngenhariaMec } from "./pages/EngenhariaMec";
import { EngenhariaCivil } from "./pages/EngenhariaCivil";
import { Utilitarios } from "./pages/Utilitarios";

function App() {
  return (
    <BrowserRouter>
      <Head />
      <Routes>
        <Route path="/" element={<Inicio />} />

        <Route path="/registros" element={<Registros />} />
        <Route path="/engenhariamec" element={<EngenhariaMec />} />
        <Route path="/engenhariacivil" element={<EngenhariaCivil />} />
        <Route path="/fale-conosco" element={<FaleConosco />} />
        <Route path="/utilitarios" element={<Utilitarios />} />
      </Routes>
      <WhatsAppButton />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
