import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./styles.css";

export function WhatsAppButton() {
  const openWhatsApp = () => {
    const message =
      "Olá, entrei no seu site e gostaria de saber mais sobre seus serviços.";
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = "5521994967332";
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  return (
    <div className="whatsapp-icon" onClick={openWhatsApp}>
      <WhatsAppIcon />
    </div>
  );
}
