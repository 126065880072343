import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo-crea.png";
import ABNT from "../../assets/abnt.png";
import "./styles.css";

export function Head() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="container-head">
      <div className="title">
        <div className="text-container">
          <span>Empresa</span>
          <span> Credenciada</span>
        </div>
        <img src={Logo} width={50} alt="Logotipo do Crea-RJ" />
        <img
          src={ABNT}
          width={45}
          className="change-color"
          alt="Logotipo da ABNT"
        />
      </div>

      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>

      <div className={`buttons ${menuOpen ? "open" : ""}`}>
        <div onClick={toggleMenu}>
          {location.pathname !== "/" && (
            <Link to="/">
              <button>INÍCIO</button>
            </Link>
          )}
        </div>
        <div onClick={toggleMenu}>
          <Link to="/engenhariamec">
            <button>ENGENHARIA MECÂNICA</button>
          </Link>
        </div>
        <div onClick={toggleMenu}>
          <Link to="/engenhariacivil">
            <button>ENGENHARIA CIVIL</button>
          </Link>
        </div>

        <div onClick={toggleMenu}>
          <Link to="/registros">
            <button>REGISTROS</button>
          </Link>
        </div>
        <div onClick={toggleMenu}>
          <Link to="/fale-conosco">
            <button>FALE CONOSCO</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
