import React, { useState } from "react";
import "./styles.css";
import Carros from "../../assets/Carros.png";

const itens = [
  { id: 1, descricao: "Teto", valor: 1 },
  { id: 2, descricao: "Capô", valor: 1 },
  { id: 3, descricao: "Painel dash", valor: 3 },
  { id: 4, descricao: "Painel dianteiro", valor: 1 },
  { id: 5, descricao: "Quadro / Suporte do motor", valor: 2 },
  { id: 6, descricao: "Longarina Completa / Caixa de roda esq.", valor: 3 },
  { id: 7, descricao: "Longarina Parcial / Avental esquerdo", valor: 1 },
  { id: 8, descricao: "Chassi porção dianteira (veículos carga)", valor: 3 },
  { id: 9, descricao: "Pára-lama dianteiro esquerdo", valor: 1 },
  { id: 10, descricao: "Suspensão dianteira esquerdo", valor: 2 },
  { id: 11, descricao: "Coluna dianteira externa esquerda", valor: 1 },
  { id: 12, descricao: "Coluna dianteira externa e estrutura esq.", valor: 3 },
  { id: 13, descricao: "Porta dianteira esquerda", valor: 1 },
  { id: 14, descricao: "Soleira externa esquerda", valor: 1 },
  { id: 15, descricao: "Soleira externa e estrutura esquerda", valor: 3 },
  { id: 16, descricao: "Assoalho central esquerdo", valor: 3 },
  { id: 17, descricao: "Coluna central externa esquerda", valor: 1 },
  { id: 18, descricao: "Coluna central externa e estrutura esq.", valor: 3 },
  { id: 19, descricao: "Porta traseira esquerda", valor: 1 },
  { id: 20, descricao: "Coluna traseira externa esquerda", valor: 1 },
  { id: 21, descricao: "Coluna traseira externa e estrutura esq.", valor: 3 },
  { id: 22, descricao: "Lateral traseira esquerda", valor: 1 },
  { id: 23, descricao: "Suspensão traseira esquerdo", valor: 2 },
  { id: 24, descricao: "Tampa traseira Painel Traseiro / divisor", valor: 1 },
  { id: 25, descricao: "Longarina traseira esquerda", valor: 3 },
  { id: 26, descricao: "Caixa de Roda traseira esquerda", valor: 3 },
  { id: 27, descricao: "Assoalho porta-malas / Assoalho caçamba", valor: 1 },
  { id: 28, descricao: "Caixa de Roda traseira direita", valor: 3 },
  { id: 29, descricao: "Longarina traseira direita", valor: 3 },
  { id: 30, descricao: "Chassi porção traseira (veículos carga)", valor: 3 },
  { id: 31, descricao: "Suspensão traseira direita", valor: 2 },
  { id: 32, descricao: "Lateral traseira direita", valor: 1 },
  { id: 33, descricao: "Coluna traseira externa direita", valor: 1 },
  { id: 34, descricao: "Coluna traseira externa e estrutura direita", valor: 3 },
  { id: 35, descricao: "Porta traseira direita", valor: 1 },
  { id: 36, descricao: "Coluna central externa direita", valor: 1 },
  { id: 37, descricao: "Coluna central externa e estrutura direita", valor: 3 },
  { id: 38, descricao: "Soleira externa direita", valor: 1 },
  { id: 39, descricao: "Soleira externa e estrutura direita", valor: 3 },
  { id: 40, descricao: "Assoalho central direito", valor: 3 },
  { id: 41, descricao: "Porta dianteira direita", valor: 1 },
  { id: 42, descricao: "Coluna dianteira externa direita", valor: 1 },
  { id: 43, descricao: "Coluna dianteira externa e estrutura direita", valor: 3 },
  { id: 44, descricao: "Pára-lama dianteiro direito", valor: 1 },
  { id: 45, descricao: "Suspensão dianteira direito", valor: 2 },
  { id: 46, descricao: "Longarina completa / Caixa de roda Dir.", valor: 3 },
  { id: 47, descricao: "Longarina parcial / Avental direita", valor: 1 },
  { id: 48, descricao: "Longarina traseira esquerda", valor: 3 },
  { id: 49, descricao: "Air Bag Motorista", valor: 0 },
  { id: 50, descricao: "Air Bag Passageiro", valor: 0 },
  { id: 51, descricao: "Air Bag Lateral", valor: 0 },
  { id: 52, descricao: "Local de gravaçao do VIN", valor: 0 },

];


export function RelatorioAvarias() {
  const [avarias, setAvarias] = useState(itens.map((item) => ({ ...item, value: 0 })));

  const handleRadioChange = (itemId, value) => {
    const updatedAvarias = avarias.map((avaria) =>
      avaria.id === itemId ? { ...avaria, value } : avaria
    );
    setAvarias(updatedAvarias);
  };

  const calcularTotal = () => {
    return avarias.reduce((total, avaria) => total + avaria.valor * avaria.value, 0);
  };

  const obterClassificacao = (total) => {
    if (total <= 20) {
      return "DANO DE PEQUENA MONTA";
    } else if (total <= 30) {
      return "DANO DE MÉDIA MONTA";
    } else {
      return "DANO DE GRANDE MONTA";
    }
  };

  const total = calcularTotal();
  const classificacao = obterClassificacao(total);


  return (
    <>
      <div className="form">
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Descrição</th>
              <th>Valor</th>
              <th>Sim</th>
              <th>Não</th>
              <th>N/A</th>
            </tr>
          </thead>
          <tbody>
            {avarias.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.descricao}</td>
                <td>{item.valor}</td>
                <td>
                  <input
                    type="radio"
                    name={`radio-${item.id}`}
                    value={item.id}
                    checked={item.value === 1}
                    onChange={() => handleRadioChange(item.id, 1)}
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    name={`radio-${item.id}`}
                    value={item.id}
                    checked={item.value === 0}
                    onChange={() => handleRadioChange(item.id, 0)}
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    name={`radio-${item.id}`}
                    value={item.id}
                    checked={item.value === -1}
                    onChange={() => handleRadioChange(item.id, 0)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div>
        <h3>
          TOTAL DE PONTOS: {calcularTotal()}
        </h3>
      </div>

      <div>
        <h3>{classificacao}</h3>
      </div>

    </>

  );
}

export function Avaliacao() {
  return (
    <>
      <div className="avaliacao">

        <p>
          I – Danos de pequena monta, quando o veículo sofrer danos que afetem
          peças externas e/ou peças mecânicas e estruturais, mas sua pontuação,
          não ultrapasse 16 pontos, desde que não afete nenhum componente estrutural;
        </p>
        <p>
          II – Danos de média monta, quando o veículo sofrer danos em suas
          peças externas, peças mecânicas e estruturais e sua pontuação, esteja acima de 16
          pontos, desde que não afete dois ou mais componentes estruturais;
        </p>
        <p>
          III – Danos de grande monta, quando o veículo sofrer dano em dois ou
          mais componentes estruturais, independente do somatório de pontos.
        </p>
      </div>
    </>
  )
}



export function Cabecalho() {
  const [formData, setFormData] = useState({
    nome: "",
    modelo: "",
    chassis: "",
    placa: "",
    numeroBOAT: "",
    data: "",
    observacao: "",
  });

  return (
    <form>
      <div className="campo-form">
        <label>
          Nome:
          <input type="text" name="nome" />
        </label>
      </div>

      <div className="campo-form">
        <label>
          Modelo:
          <input type="text" name="modelo" />
        </label>
      </div>

      <div className="campo-form">
        <label>
          Chassis:
          <input type="text" name="chassis" />
        </label>
      </div>

      <div className="campo-form">
        <label>
          Placa:
          <input type="text" name="placa" />
        </label>
      </div>

      <div className="campo-form">
        <label>
          Número BOAT:
          <input type="text" name="numeroBOAT" />
        </label>
      </div>

      <div className="campo-form">
        <label>
          Data:
          <input type="date" name="data" />
        </label>
      </div>

      <div className="campo-form">
        <label>
          Observação:
          <textarea name="observacao" />
        </label>
      </div>
    </form>
  );
}



export function Utilitarios() {
  return (
    <div className="container-servicos">
      <div className="util-container">
        <img src={Carros} alt="Imagem de veículos reunidos - Carros, moto, caminhão" className="engrenagem" />
        <h2>Relatório de avarias para classificação de Danos - PMG</h2>
      </div>

      <div><Cabecalho /></div>

      <div className="relatorio-container">
        <RelatorioAvarias />
      </div>
      <Avaliacao />
    </div>
  );
}
