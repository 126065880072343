import React from "react";
import "./styles.css";
import IconCREA from "../../assets/crea.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { faHardHat } from "@fortawesome/free-solid-svg-icons";

export function Registros() {
  return (
    <div className="container-registros">
      <div className="registros-mask"></div>
      <div className="registros-content">
        <h2>
          <FontAwesomeIcon icon={faWrench} /> NOSSOS REGISTROS{" "}
          <FontAwesomeIcon icon={faHardHat} />
        </h2>
        <p>
          A nossa empresa e todos os nossos funcionários são devidamente
          registrados no Conselho Regional de Engenharia e Agronomia (CREA),
          garantindo a legalidade e a qualidade dos serviços que prestamos.
        </p>
        <p>
          O CREA é responsável por assegurar que os nossos profissionais de
          engenharia civil e Mecânica atendam aos padrões técnicos e éticos,
          promovendo a excelência e a segurança em todas as nossas atividades.
        </p>
        <p>
          Trabalhar sob o registro do CREA é uma demonstração do nosso
          compromisso com a excelência e com a entrega de resultados confiáveis
          e de alta qualidade para os nossos clientes.
        </p>
      </div>
      <div className="crea-icons">
        <a
          href="https://portalservicos.crea-rj.org.br/#/app/consultas/profissionais"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>CREA-RJ PESSOA FÍSICA</p>
          <img src={IconCREA} alt="Ícone do CREA" />
        </a>
        <a
          href="https://portalservicos.crea-rj.org.br/#/app/consultas/empresas"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>CREA-RJ PESSOA JURÍDICA</p>
          <img src={IconCREA} alt="Ícone do CREA" />
        </a>
      </div>
    </div>
  );
}
