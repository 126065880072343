import "./styles.css";

export function Footer() {
  return (
    <footer>
      <div className="container-footer">
        <p>&copy; 2023 Perícia na Rede / All Rights Reserved </p>
        <div className="dev">
          <span>
            {" "}
            <span>
              <a
                href="https://marceloabbadia.github.io/Projdev/"
                target="_blank"
                rel="noreferrer"
              >
                - Desenv.Marcelo Abbadia
              </a>
            </span>
          </span>
        </div>
      </div>
    </footer>
  );
}
