import "./styles.css";
import Civil from "../../assets/civil.png";

export function EngenhariaCivil() {
  return (
    <div className="container-servicos">
      <div className="civil-container">
        <img
          src={Civil}
          alt="Capacete e trena da engenharia civil"
          className="civil"
        />
        <h2>PERÍCIAS JUDICIAIS ENGENHARIA CIVIL</h2>
      </div>

      <div className="row">
        <div className="content-box">
          <h3>O que é?</h3>
          <p>
            A perícia judicial de engenharia civil é uma avaliação técnica
            realizada por um engenheiro civil especializado, com o objetivo de
            esclarecer questões técnicas em processos judiciais relacionados à
            construção civil, estruturas e obras de infraestrutura.
          </p>
        </div>

        <div className="content-box">
          <h3>Normas e Resoluções:</h3>
          <ul>
            <li>
              ABNT: Seguimos rigorosamente as normas estabelecidas pela
              Associação Brasileira de Normas Técnicas (ABNT) pertinentes à
              engenharia civil.
            </li>
            <li>
              CREA: Atuamos em conformidade com as resoluções e diretrizes do
              Conselho Regional de Engenharia e Agronomia (CREA), garantindo a
              legalidade e a qualidade dos serviços prestados.
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="content-box">
          <h3>Por que contratar?</h3>
          <ul>
            <li>
              Precisão Técnica: Nossos profissionais são altamente qualificados
              e atualizados com as mais recentes tecnologias e normativas da
              engenharia civil.
            </li>
            <li>
              Imparcialidade: Como peritos judiciais, garantimos uma avaliação
              imparcial, baseada estritamente em critérios técnicos.
            </li>
            <li>
              Valor Jurídico: Um laudo pericial bem fundamentado pode ser
              determinante para a resolução de um processo judicial.
            </li>
          </ul>
        </div>
        <div className="content-box">
          <h3>Compromisso com a Excelência:</h3>
          <p>
            Nosso compromisso é fornecer um serviço de perícia de alta
            qualidade, contribuindo para a justiça e a verdade técnica em
            processos judiciais. Contate-nos para mais informações e saiba como
            podemos ajudar em seu caso.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="content-box">
          <h3>Áreas de Atuação I:</h3>

          <ul>
            <li>
              <strong>Avaliação de Estruturas:</strong> Determinar a integridade
              e valor de estruturas como edifícios, pontes e barragens.
            </li>
            <li>
              <strong>Análise de Falhas em Construções:</strong> Investigar as
              causas de falhas ou colapsos em edificações.
            </li>
            <li>
              <strong> Acidentes em Obras:</strong> Avaliar as causas de
              acidentes em canteiros de obras e construções.
            </li>
            <li>
              <strong>Avaliações de Conformidade com Normas Técnicas: </strong>
              Verificar se construções e infraestruturas estão em conformidade
              com normas técnicas aplicáveis.
            </li>
            <li>
              <strong>Análise de Patologias em Edificações:</strong> Avaliar
              problemas como infiltrações, fissuras e outros defeitos
              construtivos.
            </li>
            <li>
              <strong>Estudos Geotécnicos:</strong> Analisar problemas
              relacionados ao solo, fundações e contenções.
            </li>
            <li>
              <strong>Avaliação de Impactos Ambientais:</strong> Analisar os
              impactos de projetos de construção no meio ambiente.
            </li>
          </ul>
        </div>

        <div className="content-box">
          <h3>Áreas de Atuação II:</h3>
          <ul>
            <li>
              <strong>Avaliação de Sistemas Hidrossanitários:</strong> Analisar
              a eficiência e adequação de sistemas de abastecimento de água e
              esgoto.
            </li>
            <li>
              <strong>Estudos de Mobilidade Urbana:</strong> Avaliar projetos de
              transporte e tráfego em áreas urbanas.
            </li>
            <li>
              <strong>Avaliação de Sistemas de Drenagem:</strong> Analisar a
              eficiência de sistemas de drenagem em áreas urbanas e rurais.
            </li>
            <li>
              <strong>Análise de Projetos Civis:</strong> Avaliar a adequação e
              conformidade de projetos civis em relação a normas e boas práticas
              de engenharia.
            </li>
            <li>
              <strong> Avaliação de Processos Construtivos: </strong>Analisar
              métodos construtivos em busca de falhas, ineficiências ou não
              conformidades.
            </li>
            <li>
              <strong>Inspeções de Segurança:</strong> Verificar a segurança de
              edificações e infraestruturas.
            </li>
            <li>
              <strong>Avaliações relacionadas a Imóveis:</strong> Como
              avaliações imobiliárias, vistorias de vizinhança, entre outros.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
