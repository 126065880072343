import "./styles.css";
import Mec from "../../assets/Mec.png";
export function EngenhariaMec() {
  return (
    <div className="container-servicos">
      <div className="engrenagem-container">
        <img src={Mec} alt="Engrenagem" className="engrenagem" />
        <h2>PERÍCIAS JUDICIAIS ENGENHARIA MECÂNICA</h2>
      </div>

      <div className="row1">
        <div className="content-box1">
          <h3>O que é?</h3>
          <p>
            A perícia judicial de engenharia mecânica é uma avaliação técnica
            realizada por um engenheiro mecânico especializado, com o objetivo
            de esclarecer questões técnicas em processos judiciais.
          </p>
        </div>

        <div className="content-box1">
          <h3>Normas e Resoluções:</h3>
          <ul>
            <li>
              ABNT: Seguimos rigorosamente as normas estabelecidas pela
              Associação Brasileira de Normas Técnicas (ABNT) pertinentes à
              engenharia mecânica.
            </li>
            <li>
              CREA: Atuamos em conformidade com as resoluções e diretrizes do
              Conselho Regional de Engenharia e Agronomia (CREA), garantindo a
              legalidade e a qualidade dos serviços prestados.
            </li>
          </ul>
        </div>
      </div>

      <div className="row1">
        <div className="content-box1">
          <h3>Por que contratar?</h3>
          <ul>
            <li>
              Precisão Técnica: Nossos profissionais são altamente qualificados
              e atualizados com as mais recentes tecnologias e normativas da
              engenharia mecânica.
            </li>
            <li>
              Imparcialidade: Como peritos judiciais, garantimos uma avaliação
              imparcial, baseada estritamente em critérios técnicos.
            </li>
            <li>
              Valor Jurídico: Um laudo pericial bem fundamentado pode ser
              determinante para a resolução de um processo judicial.
            </li>
          </ul>
        </div>
        <div className="content-box1">
          <h3>Compromisso com a Excelência:</h3>
          <p>
            Nosso compromisso é fornecer um serviço de perícia de alta
            qualidade, contribuindo para a justiça e a verdade técnica em
            processos judiciais. Contate-nos para mais informações e saiba como
            podemos ajudar em seu caso.
          </p>
        </div>
      </div>

      <div className="row1">
        <div className="content-box1">
          <h3>Áreas de Atuação I:</h3>

          <ul>
            <li>
              <strong>Avaliação de Máquinas e Equipamentos:</strong> Determinar
              o valor de máquinas, equipamentos e instalações industriais.
            </li>
            <li>
              <strong>Análise de Falhas em Componentes Mecânicos:</strong>{" "}
              Investigar as causas de falhas em componentes, máquinas ou
              sistemas mecânicos.
            </li>
            <li>
              <strong>Acidentes de Trabalho:</strong> Avaliar as causas de
              acidentes relacionados a máquinas e equipamentos, especialmente
              quando envolvem questões de segurança.
            </li>
            <li>
              <strong>Avaliações de Conformidade com Normas Técnicas:</strong>{" "}
              Verificar se máquinas, equipamentos ou processos estão em
              conformidade com normas técnicas aplicáveis.
            </li>
            <li>
              <strong>Análise de Desgaste e Vida Útil:</strong> Avaliar o
              desgaste de componentes e determinar sua vida útil restante.
            </li>
            <li>
              <strong>Estudos Térmicos:</strong> Analisar problemas relacionados
              à transferência de calor e processos térmicos.
            </li>
            <li>
              <strong>Avaliação de Ruído e Vibrações:</strong> Analisar níveis
              de ruído e vibrações em ambientes de trabalho ou em equipamentos
              específicos.
            </li>
          </ul>
        </div>

        <div className="content-box1">
          <h3>Áreas de Atuação II:</h3>
          <ul>
            <li>
              <strong>Avaliação de Ruído e Vibrações:</strong> Analisar níveis
              de ruído e vibrações em ambientes de trabalho ou em equipamentos
              específicos.
            </li>
            <li>
              <strong>Estudos de Eficiência Energética:</strong> Avaliar a
              eficiência energética de sistemas mecânicos ou processos
              industriais.
            </li>
            <li>
              <strong>
                Avaliação de Sistemas de Ventilação e Ar Condicionado:
              </strong>{" "}
              Analisar a eficiência e adequação de sistemas HVAC.
            </li>
            <li>
              <strong>Análise de Projetos Mecânicos:</strong> Avaliar a
              adequação e conformidade de projetos mecânicos em relação a normas
              e boas práticas de engenharia.
            </li>
            <li>
              <strong>Avaliação de Processos de Fabricação:</strong> Analisar
              processos de fabricação em busca de falhas, ineficiências ou não
              conformidades.
            </li>
            <li>
              <strong>Inspeções de Segurança:</strong> Verificar a segurança de
              instalações, máquinas e equipamentos.
            </li>
            <li>
              <strong>Avaliações relacionadas a Veículos:</strong> Como
              acidentes de trânsito, falhas mecânicas, entre outros.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
